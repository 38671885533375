<template>
  <div class="bg-light p-3 mt-3">
    <Row class=" mb-3">
      <BCol>
        <div class="fs-5">
          {{ header }}
        </div>
      </BCol>
      <BCol>
        <FormGroup>
          <FormSpan><BIconSearch /></FormSpan>
          <input
            class="form-control"
            type="text"
            :placeholder="placeholder"
            @input="val => emit(val.target.value)"
            :value="modelValue"
          />
          <Button @click="emit('')">Clear</Button>
        </FormGroup>
      </BCol>
    </Row>
    <slot />
  </div>
</template>

<script>
import icons from "../../assets/icons"
import comp from "./"

export default {
  name: "Card Gray",
  props: ["header", "modelValue", "placeholder"],
  emits: ["update:modelValue"],
  components: {
    ...comp,
    ...icons,
  },
  methods: {
    emit(val) {
      this.$emit("update:modelValue", val)
    },
  },
}
</script>
